import axios from "axios"
import { apiUrl } from "@/env"
import { authHeaders } from "./utils"

class Organization{
    constructor(){
        axios.defaults.baseURL = apiUrl
        console.debug("Host:", axios.defaults.baseURL)
    
    }
    async organizations(token, start, itemsPerPage) {
        const offset = start || 0
        const limit = itemsPerPage || 10
        return axios.get(
          `/api/organizations/?skip=${offset}&limit=${limit}`,
          authHeaders(token)
        )
      }
    
      async addOrganization(token, organization) {
        return axios.post(`/api/organizations/`, organization, authHeaders(token))
      }
      async editOrganization(token, organization) {
        return axios.put(`/api/organizations/${organization.id}`, organization, authHeaders(token))
      }
      async deleteOrganization(token, organization_id) {
        return axios.delete(`/api/organizations/${organization_id}`, authHeaders(token))
      }
        
}

const client = new Organization()

export default client
