import client from "@/api/organization"

const state = () => ({
  organizations: { items: [], meta: { total: 0, start: 0, per_page: 10 } },
})

const mutations = {
  organizations(state, val) {
    state.organizations = val
  },
}

const actions = {
  async actionCheckApiError({ dispatch }, payload) {
    if (payload.response.status === 401) {
      await dispatch("logout")
    }
  },
  async loadOrganizations({ dispatch, commit, state, rootState }, payload) {
    const start =
      payload.start != undefined
        ? payload.start
        : state.organizations.meta.start
    const limit = payload.limit || state.organizations.meta.per_page
    try {
      const response = await client.organizations(
        rootState.users.token,
        start,
        limit
      )
      if (response.data) {
        commit("organizations", response.data)
      }
    } catch (err) {
      console.error("organizations", err)
      await dispatch("error")
    }
  },
  async addOrganization({ dispatch, rootState }, payload) {
    delete payload.roles
    try {
      const response = await client.addOrganization(
        rootState.users.token,
        payload
      )
      if (response.status == 200) {
        dispatch("loadOrganizations", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async editOrganization({ dispatch, rootState }, payload) {
    delete payload.roles
    try {
      const response = await client.editOrganization(
        rootState.users.token,
        payload
      )
      if (response.status == 200) {
        dispatch("loadOrganizations", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async deleteOrganization({ dispatch, rootState }, organization_id) {
    try {
      const response = await client.deleteOrganization(
        rootState.users.token,
        organization_id
      )
      if (response.status == 200) {
        dispatch("loadOrganizations", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
