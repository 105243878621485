import client from "@/api/application"

const state = () => ({
  applications: { items: [], meta: { total: 0, start: 0, per_page: 10 } },
})

const mutations = {
  applications(state, val) {
    state.applications = val
  },
}

const actions = {
  async actionCheckApiError({ dispatch }, payload) {
    if (payload.response.status === 401) {
      await dispatch("logout")
    }
  },
  async loadApplications({ dispatch, commit, state, rootState }, payload) {
    const start =
      payload.start != undefined ? payload.start : state.applications.meta.start
    const limit = payload.limit || state.applications.meta.per_page
    try {
      const response = await client.applications(
        rootState.users.token,
        start,
        limit
      )
      if (response.data) {
        commit("applications", response.data)
      }
    } catch (err) {
      console.error("applications", err)
      await dispatch("error")
    }
  },
  async addApplication({ dispatch, rootState }, payload) {
    delete payload.roles
    try {
      const response = await client.addApplication(
        rootState.users.token,
        payload
      )
      if (response.status == 200) {
        dispatch("loadApplications", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async editApplication({ dispatch, rootState }, payload) {
    delete payload.roles
    try {
      const response = await client.editApplication(
        rootState.users.token,
        payload
      )
      if (response.status == 200) {
        dispatch("loadApplications", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async deleteApplication({ dispatch, rootState }, application_id) {
    try {
      const response = await client.deleteApplication(
        rootState.users.token,
        application_id
      )
      if (response.status == 200) {
        dispatch("loadApplications", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
