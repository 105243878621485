<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
    dark
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logo-psychomeasure-icon.png')"
          max-height="50px"
          max-width="50px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title title-header">
            <span class="light">Psycho</span>measure
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Record"
        :to="{ name: 'record' }"
        :icon="icons.mdiMicrophone"
      ></nav-menu-link>
      <nav-menu-link
        title="Sessions"
        :to="{ name: 'sessions' }"
        :icon="icons.mdiPlaylistMusic"
      ></nav-menu-link>
      <!--
      <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      -->
      <template v-if="isAdmin">
        <nav-menu-section-title title="ADMINISTRATION"></nav-menu-section-title>
        <nav-menu-link
          v-if="can('applications') && isSuperAdmin"
          title="Applications"
          :to="{ name: 'admin-applications' }"
          :icon="icons.mdiApplication"
        ></nav-menu-link>
        <nav-menu-link
          v-if="can('organizations') && isSuperAdmin"
          title="Organizations"
          :to="{ name: 'admin-organizations' }"
          :icon="icons.mdiSitemap"
        ></nav-menu-link>
        <nav-menu-link
          v-if="can('users')"
          title="Users"
          :to="{ name: 'admin-users' }"
          :icon="icons.mdiAccountMultiple"
        ></nav-menu-link>
        <nav-menu-link
          v-if="can('auth')"
          title="Roles"
          :to="{ name: 'admin-roles' }"
          :icon="icons.mdiShieldAccount"
        ></nav-menu-link>
        <nav-menu-link
          v-if="can('auth')"
          title="Permissions"
          :to="{ name: 'admin-permissions' }"
          :icon="icons.mdiShieldKey"
        ></nav-menu-link>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAccountCogOutline,
  mdiAccountMultiple,
  mdiShieldAccount,
  mdiShieldKey,
  mdiMicrophone,
  mdiPlaylistMusic,
  mdiSitemap,
  mdiApplication,
} from "@mdi/js"
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue"
import NavMenuLink from "./components/NavMenuLink.vue"
import { mapGetters, mapState } from "vuex"

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiHomeOutline,

        mdiAccountCogOutline,
        mdiAccountMultiple,
        mdiShieldAccount,
        mdiShieldKey,
        mdiMicrophone,
        mdiPlaylistMusic,
        mdiSitemap,
        mdiApplication,
      },
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.users.currentUser,
      isAdmin: state => state.users.isAdmin,
      isSuperAdmin: state => state.users.isSuperAdmin,
    }),
    ...mapGetters("users", ["guardAny"]),
  },
  methods: {
    can(feature) {
      return this.guardAny(["manage_" + feature, "edit_" + feature])
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
