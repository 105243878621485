import router from "@/router"
import client from "@/api/measure"

const state = () => ({
  algo: "aws",
  lang: "en",
  procesing: false,

  uuid: null,

  audio: null,
  text: "",
  meta: [],
  measurements: {},
  index: 0,
  analysis: {},
  graph: null,
  graph_index: null,
  graph_histogram: null,
  graph_lscz: null,
})

const actions = {
  start({ commit }) {
    commit("text", null)
    commit("graph", null)
    commit("measurements", {})
    router.push({ name: "record" })
  },
  async processRecording({ state, commit, rootState }) {
    commit("procesing", true)
    const formData = new FormData()
    formData.append("audio", state.audio)
    var session = await client.transcribe(
      rootState.users.token,
      state.lang,
      formData,
      state.algo
    )
    if (session) {
      session = await client.session(rootState.users.token, session.uuid)
      if (session) {
        const transcription = session.analysis.find(
          item => item.type == "transcription"
        )
        if (transcription) {
          var result = transcription.result
          commit("uuid", session.uuid)
          commit("text", result.text)
          commit("procesing", false)
          router.push({ name: "text" })
        }
      }
    } else {
      commit("procesing", false)
    }
  },
  async processUpload({ state, commit, rootState }) {
    commit("procesing", true)
    const formData = new FormData()
    formData.append("audio", state.audio)
    var session = await client.transcribe(
      rootState.users.token,
      state.lang,
      formData,
      state.algo
    )
    if (session) {
      session = await client.session(rootState.users.token, session.uuid)
      if (session) {
        const transcription = session.analysis.find(
          item => item.type == "transcription"
        )
        if (transcription) {
          var result = transcription.result
          commit("uuid", session.uuid)
          commit("text", result.text)
          commit("procesing", false)
          router.push({ name: "text" })
        }
      }
    } else {
      commit("processing", false)
    }
  },
  async processText({ state, commit, rootState }) {
    commit("procesing", true)
    var session = await client.cognitiveAnalysis(
      rootState.users.token,
      state.uuid,
      state.text
    )
    session = await client.session(rootState.users.token, session.uuid)
    if (session) {
      const cognitive = session.analysis.find(
        item => item.analysis_type == "cognitive"
      )
      if (cognitive) {
        const result = cognitive.result
        commit("graph", result.graph)
        commit("graph_index", result.graph_index)
        commit("graph_histogram", result.graph_comparative)
        commit("graph_lscz", result.graph_lscz)

        commit("measurements", result.measurements)
        commit("index", result.desorganization_index)
        commit("analysis", result.cognitive_analysis)
        commit("procesing", false)
        router.push({ name: "graph" })
      }
    }
  },
  async processTranscription({ commit, rootState }, payload) {
    commit("procesing", true)
    var session = await client.process(
      rootState.users.token,
      payload.uuid,
      payload.lang
    )
    session = await client.session(rootState.users.token, payload.uuid)
    if (session) {
      const cognitive = session.analysis.find(
        item => item.analysis_type == "cognitive"
      )
      if (cognitive) {
        const result = cognitive.result
        commit("graph", result.graph)
        commit("graph_index", result.graph_index)
        commit("graph_histogram", result.graph_comparative)
        commit("graph_lscz", result.graph_lscz)

        commit("measurements", result.measurements)
        commit("index", result.desorganization_index)
        commit("analysis", result.cognitive_analysis)
        commit("procesing", false)
        router.push({ name: "graph" })
      }
    }
  },
  async cognitiveAnalysis({ commit, dispatch, rootState }, payload) {
    commit("procesing", true)
    var session = await client.cognitiveAnalysis(
      rootState.users.token,
      payload.uuid,
      payload.text
    )
    session = await client.session(rootState.users.token, session.uuid)
    if (session) {
      const cognitive = session.analysis.find(
        item => item.analysis_type == "cognitive"
      )
      if (cognitive) {
        commit("procesing", false)
        dispatch("sessions/loadSession", session.uuid, { root: true })
      }
    }
  },
  async sentimentAnalysis({ commit, dispatch, rootState }, payload) {
    commit("procesing", true)
    var session = await client.sentimentAnalysis(
      rootState.users.token,
      payload.uuid,
      payload.text,
      payload.meta
    )
    session = await client.session(rootState.users.token, session.uuid)
    if (session) {
      const sentiment = session.analysis.find(
        item => item.analysis_type == "sentiment"
      )
      if (sentiment) {
        commit("procesing", false)
        dispatch("sessions/loadSession", session.uuid, { root: true })
      }
    }
  },
  async cognitiveAnalysisText({ commit, rootState }, payload) {
    commit("procesing", true)
    var session = await client.processText(
      rootState.users.token,
      payload.lang,
      payload.text,
      payload.meta
    )
    var finished = false
    var cognitive = null
    while (!finished) {
      session = await client.session(rootState.users.token, session.uuid)
      if (session) {
        cognitive = session.analysis.find(
          item => item.analysis_type == "cognitive"
        )
        if (cognitive) {
          finished = cognitive.status == "finished"
        }
        setTimeout(() => {}, 2000)
      }
    }
    commit("procesing", false)
    const result = cognitive.result
    commit("graph", result.graph)
    commit("graph_index", result.graph_index)
    commit("graph_histogram", result.graph_comparative)
    commit("graph_lscz", result.graph_lscz)

    commit("measurements", result.measurements)
    commit("index", result.desorganization_index)
    commit("analysis", result.cognitive_analysis)
    commit("procesing", false)
    router.push({ name: "graph" })
  },
}
const mutations = {
  changeLang(state, val) {
    state.lang = val
  },
  procesing(state, val) {
    state.procesing = val
  },
  audio(state, val) {
    state.audio = val
  },
  text(state, val) {
    state.text = val
  },
  measurements(state, val) {
    state.measurements = val
  },
  index(state, val) {
    state.index = val
  },
  analysis(state, val) {
    state.analysis = val
  },
  graph(state, val) {
    state.graph = val
  },
  graph_index(state, val) {
    state.graph_index = val
  },
  graph_histogram(state, val) {
    state.graph_histogram = val
  },
  graph_lscz(state, val) {
    state.graph_lscz = val
  },
  uuid(state, val) {
    state.uuid = val
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
