import axios from "axios"
import { apiUrl } from "@/env"
import { authHeaders } from "./utils"

class Application {
  constructor() {
    axios.defaults.baseURL = apiUrl
    console.debug("Host:", axios.defaults.baseURL)
  }
  async applications(token, start, itemsPerPage) {
    const offset = start || 0
    const limit = itemsPerPage || 10
    return axios.get(
      `/api/applications/?skip=${offset}&limit=${limit}`,
      authHeaders(token)
    )
  }

  async addApplication(token, application) {
    return axios.post(`/api/applications/`, application, authHeaders(token))
  }
  async editApplication(token, application) {
    return axios.put(
      `/api/applications/${application.id}`,
      application,
      authHeaders(token)
    )
  }
  async deleteApplication(token, application_id) {
    return axios.delete(
      `/api/applications/${application_id}`,
      authHeaders(token)
    )
  }
}

const client = new Application()

export default client
