import Vue from "vue"
import VueRouter from "vue-router"
import RouterComponent from "@/components/RouterComponent.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "start" */ "@/views/Start.vue"),
    children: [
      /** External */
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
        meta: {
          layout: "blank",
        },
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "@/views/auth/Register.vue"
          ),
        meta: {
          layout: "blank",
        },
      },
      {
        path: "recover-password",
        name: "recover-password",
        component: () =>
          import(
            /* webpackChunkName: "recover-password" */ "@/views/auth/PasswordRecovery.vue"
          ),
        meta: {
          layout: "blank",
        },
      },
      {
        path: "reset-password",
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "@/views/NotFound.vue"
            /* webpackChunkName: "reset-password" */ //"./views/ResetPassword.vue"
          ),
        meta: {
          layout: "blank",
        },
      },
      /** Internal */
      {
        path: "dashboard",
        name: "dashboard",
        component: RouterComponent,
        redirect: "dashboard/index",
        children: [
          {
            path: "index",
            name: "dashboard/index",
            component: () =>
              import(
                /* webpackChunkName: "dashboard-index" */ "@/views/dashboard/Dashboard.vue"
              ),
          },
        ],
      },
      {
        path: "sessions",
        component: RouterComponent,
        redirect: "sessions/record",
        children: [
          {
            path: "index",
            name: "sessions",
            component: () =>
              import(
                /* webpackChunkName: "index" */ "@/views/session/SessionList.vue"
              ),
          },
          {
            path: ":id",
            name: "session",
            component: () =>
              import(
                /* webpackChunkName: "index" */ "@/views/session/SessionView.vue"
              ),
          },
          {
            path: "record",
            name: "record",
            component: () =>
              import(
                /* webpackChunkName: "record" */ "@/views/session/Record.vue"
              ),
          },
          {
            path: "record-text",
            name: "record-text",
            component: () =>
              import(
                /* webpackChunkName: "record-text" */ "@/views/session/RecordText.vue"
              ),
          },
          {
            path: "/upload",
            name: "upload",
            component: () =>
              import(
                /* webpackChunkName: "upload" */ "@/views/session/Upload.vue"
              ),
          },
          {
            path: "text",
            name: "text",
            meta: { requireAudio: true },
            component: () =>
              import(
                /* webpackChunkName: "text" */ "@/views/session/TextResults.vue"
              ),
          },
          {
            path: "graph",
            name: "graph",
            meta: { requireAudio: true },
            component: () =>
              import(
                /* webpackChunkName: "graph" */ "@/views/session/GraphResults.vue"
              ),
          },
        ],
      },
      /** Admin */
      {
        path: "admin",
        component: () =>
          import(
            /* webpackChunkName: "main-admin" */ "@/views/admin/Admin.vue"
          ),
        redirect: "admin/users",
        children: [
          {
            path: "organizations",
            name: "admin-organizations",
            component: () =>
              import(
                /* webpackChunkName: "admin-organizations" */ "@/views/admin/OrganizationList.vue"
              ),
          },
          {
            path: "applications",
            name: "admin-applications",
            component: () =>
              import(
                /* webpackChunkName: "admin-applications" */ "@/views/admin/ApplicationList.vue"
              ),
          },
          {
            path: "users",
            name: "admin-users",
            component: () =>
              import(
                /* webpackChunkName: "admin-users" */ "@/views/admin/UserList.vue"
              ),
          },
          {
            path: "roles",
            name: "admin-roles",
            component: () =>
              import(
                /* webpackChunkName: "admin-roles" */ "@/views/admin/RoleList.vue"
              ),
          },
          {
            path: "permissions",
            name: "admin-permissions",
            component: () =>
              import(
                /* webpackChunkName: "admin-permissions" */ "@/views/admin/PermissionList.vue"
              ),
          },
        ],
      },
    ],
  },

  /* TODO REMOVE */
  /*
  {
    path: "/pages/account-settings",
    name: "pages-account-settings",
    component: () =>
      import("@/views/pages/account-settings/AccountSettings.vue"),
  },
*/
  /** General pages */
  {
    path: "/error-404",
    name: "error-404",
    component: () =>
      import(/* webpackChunkName: "error-404" */ "@/views/Error.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
