import client from "@/api/session"

const state = () => ({
  sessions: { items: [], meta: { total: 0, start: 0, per_page: 10 } },
  activeSession: {},
})

const mutations = {
  sessions(state, val) {
    state.sessions = val
  },
  activeSession(state, val) {
    state.activeSession = val
  },
}

const actions = {
  async actionCheckApiError({ dispatch }, payload) {
    if (payload.response.status === 401) {
      await dispatch("logout")
    }
  },
  async loadSessions({ dispatch, commit, state, rootState }, payload) {
    const start =
      payload.start != undefined ? payload.start : state.sessions.meta.start
    const limit = payload.limit || state.sessions.meta.per_page
    const detailed = payload.detailed || false
    try {
      const response = await client.sessions(
        rootState.users.token,
        start,
        limit,
        detailed
      )
      if (response.data) {
        commit("sessions", response.data)
      }
    } catch (err) {
      console.error("sessions", err)
      await dispatch("error")
    }
  },
  async loadSession({ dispatch, commit, rootState }, session_id) {
    try {
      const response = await client.loadSession(
        rootState.users.token,
        session_id
      )
      if (response.status == 200) {
        commit("activeSession", response.data)
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async deleteSession({ dispatch, rootState }, session_id) {
    try {
      const response = await client.deleteSession(
        rootState.users.token,
        session_id
      )
      if (response.status == 200) {
        dispatch("loadSessions", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
