import "@/plugins/vue-composition-api"
import "@fortawesome/fontawesome-free/scss/fontawesome.scss"
import "@fortawesome/fontawesome-free/scss/solid.scss"
import "@fortawesome/fontawesome-free/scss/regular.scss"
import "@/styles/styles.scss"
import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
import AudioVisual from "vue-audio-visual"

Vue.config.productionTip = false
Vue.use(AudioVisual)

new Vue({
  router,
  store,
  vuetify,
  data: { debug: Vue.config.devtools },
  render: h => h(App),
}).$mount("#app")
