import client from "@/api/user"
import router from "@/router"

const state = () => ({
  token: null,
  isLoggedIn: false,
  isAdmin: false,
  isSuperAdmin: false,
  logInError: false,
  currentUser: {},
  users: { items: [], meta: { total: 0, start: 0, per_page: 10 } },
  roles: { items: [], meta: { total: 0, start: 0, per_page: 10 } },
  permissions: { items: [], meta: { total: 0, start: 0, per_page: 10 } },
})

const mutations = {
  token(state, val) {
    state.token = val
  },
  logInError(state, val) {
    state.logInError = val
  },
  loggedIn(state, val) {
    state.isLoggedIn = val
  },
  currentUser(state, val) {
    state.currentUser = val
  },
  isAdmin(state, val) {
    state.isAdmin = val
  },
  isSuperAdmin(state, val) {
    state.isSuperAdmin = val
  },
  users(state, val) {
    state.users = val
  },
  roles(state, val) {
    state.roles = val
  },
  permissions(state, val) {
    state.permissions = val
  },
}

const actions = {
  async login({ dispatch, commit }, payload) {
    try {
      const response = await client.login(payload.username, payload.password)
      const token = response.data.access_token
      if (token) {
        // TODO Store to localStorage
        commit("token", token)
        commit("loggedIn", true)
        await dispatch("loadMe")
        router.push({ name: "dashboard" })
      } else {
        await dispatch("logout")
      }
    } catch (err) {
      console.error(err)
      commit("logInError", true)
      //await dispatch("logout")
    }
  },
  async passwordRecovery({ commit }, username) {
    try {
      const response = await client.passwordRecovery(username)
      if (response.status == 200) {
        commit("ui/message", response.data.msg)
        //await dispatch("logout")
      }
    } catch (err) {
      console.error(err)
      commit("logInError", true)
      //await dispatch("logout")
    }
  },

  async registerUser({ commit, dispatch }, payload) {
    try {
      const response = await client.registerUser(payload)
      if (response.status == 200) {
        //TODO Commit message
        //commit("messages")
        await dispatch("login", {
          username: payload.email,
          password: payload.password,
        })
      }
    } catch (err) {
      console.error(err)
      commit("logInError", true)
      //await dispatch("logout")
    }
  },

  async loadMe({ dispatch, commit, state }) {
    try {
      const response = await client.getMe(state.token)
      if (response.data) {
        commit("currentUser", response.data)
        const admin =
          response.data.roles.find(role => role.name == "Admin") != undefined ||
          response.data.permissions.filter(
            permission =>
              permission.indexOf("manage") === 0 ||
              permission.indexOf("edit") === 0
          ).length > 0
        commit("isAdmin", admin)
        commit("isSuperAdmin", admin && response.data.organization_id == 1) // TODO Change this
      }
    } catch (err) {
      await dispatch("checkApiError")
    }
  },

  async actionCheckApiError({ dispatch }, payload) {
    if (payload.response.status === 401) {
      await dispatch("logout")
    }
  },
  async loadUsers({ dispatch, commit, state }, payload) {
    const start =
      payload.start != undefined ? payload.start : state.users.meta.start
    const limit = payload.limit || state.users.meta.per_page
    try {
      const response = await client.users(state.token, start, limit)
      if (response.data) {
        commit("users", response.data)
      }
    } catch (err) {
      console.error("users", err)
      await dispatch("error")
    }
  },
  async addUser({ dispatch, state }, payload) {
    var roles = payload.roles || []
    delete payload.roles
    try {
      const response = await client.addUser(state.token, payload)
      if (response.status == 200) {
        const user = response.data
        roles = roles.map(item => ({ name: item }))
        if (JSON.stringify(user.roles) !== JSON.stringify(roles)) {
          await client.addRoles(state.token, user.id, roles)
        }
        dispatch("loadUsers", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async editUser({ dispatch, state }, payload) {
    var roles = payload.roles || []
    delete payload.roles
    try {
      const response = await client.editUser(state.token, payload)
      if (response.status == 200) {
        const user = response.data
        roles = roles.map(item => ({ name: item }))
        if (JSON.stringify(user.roles) !== JSON.stringify(roles)) {
          await client.addRoles(state.token, user.id, roles)
        }
        dispatch("loadUsers", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async deleteUser({ dispatch, state }, user_id) {
    try {
      const response = await client.deleteUser(state.token, user_id)
      if (response.status == 200) {
        dispatch("loadUsers", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },

  async loadRoles({ dispatch, commit, state }, payload) {
    const start =
      payload.start != undefined ? payload.start : state.roles.meta.start
    const limit = payload.limit || state.roles.meta.per_page
    try {
      const response = await client.roles(state.token, start, limit)
      if (response.data) {
        commit("roles", response.data)
      }
    } catch (err) {
      console.error("roles", err)
      await dispatch("error")
    }
  },
  async addRole({ dispatch, state }, payload) {
    var permissions = payload.permissions || []
    delete payload.permissions
    try {
      const response = await client.addRole(state.token, payload)
      if (response.status == 200) {
        const role = response.data
        permissions = permissions.map(item => ({ name: item }))
        if (JSON.stringify(role.permissions) !== JSON.stringify(permissions)) {
          await client.addRolePermissions(state.token, role.id, permissions)
        }
        dispatch("loadRoles", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async editRole({ dispatch, state }, payload) {
    var permissions = payload.permissions || []
    delete payload.permissions
    try {
      const response = await client.editRole(state.token, payload)
      if (response.status == 200) {
        const role = response.data
        permissions = permissions.map(item => ({ name: item }))
        if (JSON.stringify(role.permissions) !== JSON.stringify(permissions)) {
          await client.addRolePermissions(state.token, role.id, permissions)
        }
        dispatch("loadRoles", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },

  async deleteRole({ dispatch, state }, role_id) {
    try {
      const response = await client.deleteRole(state.token, role_id)
      if (response.status == 200) {
        dispatch("loadRoles", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },

  async loadPermissions({ dispatch, commit, state }, payload) {
    const start =
      payload.start != undefined ? payload.start : state.permissions.meta.start
    const limit = payload.limit || state.permissions.meta.per_page
    try {
      const response = await client.permissions(state.token, start, limit)
      if (response.data) {
        commit("permissions", response.data)
      }
    } catch (err) {
      console.error("permissions", err)
      await dispatch("error")
    }
  },

  async addPermission({ dispatch, state }, payload) {
    try {
      const response = await client.addPermission(state.token, payload)
      if (response.status == 200) {
        dispatch("loadPermissions", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
  async editPermission({ dispatch, state }, payload) {
    try {
      const response = await client.editPermission(state.token, payload)
      if (response.status == 200) {
        dispatch("loadPermissions", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },

  async deletePermission({ dispatch, state }, role_id) {
    try {
      const response = await client.deletePermission(state.token, role_id)
      if (response.status == 200) {
        dispatch("loadPermissions", {})
      }
    } catch (err) {
      dispatch("error")
    }
  },
}
const getters = {
  guard: state => permission => {
    return state.currentUser.permissions.indexOf(permission) >= 0
  },
  guardAny: state => permissions => {
    for (let index = 0; index < permissions.length; index++) {
      if (
        state.currentUser != undefined &&
        state.currentUser.permissions.indexOf(permissions[index]) >= 0
      ) {
        return true
      }
    }
    return false
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
