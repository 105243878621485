import Vue from "vue"
import Vuex from "vuex"

import speechgraph from "./modules/speechgraph"
import users from "./modules/users"
import organizations from "./modules/organizations"
import applications from "./modules/applications"
import sessions from "./modules/sessions"
import ui from "./modules/ui"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  modules: {
    speechgraph,
    users,
    organizations,
    applications,
    sessions,
    ui,
  },
  strict: debug,
  //plugins: debug ? [createLogger()] : [],
})
