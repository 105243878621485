const env = process.env.NODE_ENV;


let envApiUrl = '';

if (env === 'production') {
  envApiUrl = `https://${process.env.VUE_APP_PROD_DOMAIN}`;
} else if (env === 'staging') {
  envApiUrl = `https://${process.env.VUE_APP_STAGING_DOMAIN}`;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DEV_DOMAIN}`;
}

export const apiUrl = envApiUrl;
