import axios from "axios"
import { apiUrl } from "@/env"
import { authHeaders } from "./utils"

class Psychomeasure {
  constructor() {
    axios.defaults.baseURL = apiUrl
    console.debug("Host:", axios.defaults.baseURL)
  }
  async transcribe(token, lang, data, transcriber) {
    var algo = transcriber || "aws"
    var type = type || "dream"
    try {
      var response = await axios.post(
        "/api/sessions/audio?lang=" + lang + "&algo=" + algo + "&type=" + type,
        data,
        authHeaders(token)
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }

  async session(token, uuid) {
    try {
      var response = await axios.get(
        "/api/sessions/" + uuid,
        authHeaders(token)
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async process(token, uuid, language) {
    var lang = language || "en"
    return axios.put(
      `/api/sessions/audio/process/${uuid}?lang=${lang}&analysis_type=cognitive`,
      null,
      authHeaders(token)
    )
  }
  async processText(token, language, text, meta) {
    try {
      var lang = language || "en"
      const formData = new FormData()
      formData.append("text", text)
      if (meta != undefined) {
        formData.append("meta")
      }
      var response = await axios.post(
        `/api/sessions/text?session_type=dream&lang=${lang}&analysis_type=cognitive`,
        formData,
        authHeaders(token)
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async cognitiveAnalysis(token, uuid, text) {
    try {
      const formData = new FormData()
      formData.append("text", text)
      var response = await axios.put(
        "/api/sessions/" + uuid + "/cognitive",
        formData,
        authHeaders(token)
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async sentimentAnalysis(token, uuid, text, meta) {
    try {
      const formData = new FormData()
      formData.append("text", text)
      if (meta != undefined) {
        formData.append("meta")
      }
      var response = await axios.put(
        "/api/sessions/" + uuid + "/sentiment",
        formData,
        authHeaders(token)
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async measure(text) {
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post("/api/graph/measure", formData)
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async graph(text, cb) {
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post("/api/graph/image", formData, {
        responseType: "blob",
      })
      if (response.status == 200) {
        var reader = new FileReader()
        reader.onloadend = function () {
          cb(reader.result)
        }
        reader.readAsDataURL(response.data)
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }

  async index(text, textType) {
    var typeSpeech = textType || "dream"
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post(
        "/api/graph/index?type_speech=" + typeSpeech,
        formData
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }

  async analysis(text, textType) {
    var typeSpeech = textType || "dream"
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post(
        "/api/graph/analysis?type_speech=" + typeSpeech,
        formData
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async graph_index(text, textType, cb) {
    var typeSpeech = textType || "dream"
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post(
        "/api/graph/image_index?type_speech=" + typeSpeech,
        formData,
        {
          responseType: "blob",
        }
      )
      if (response.status == 200) {
        var reader = new FileReader()
        reader.onloadend = function () {
          cb(reader.result)
        }
        reader.readAsDataURL(response.data)
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async graph_histogram(text, textType, cb) {
    var typeSpeech = textType || "dream"
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post(
        "/api/graph/image_histogram?type_speech=" + typeSpeech,
        formData,
        {
          responseType: "blob",
        }
      )
      if (response.status == 200) {
        var reader = new FileReader()
        reader.onloadend = function () {
          cb(reader.result)
        }
        reader.readAsDataURL(response.data)
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
  async graph_lscz(text, textType, cb) {
    var typeSpeech = textType || "dream"
    const formData = new FormData()
    formData.append("text", text)
    try {
      var response = await axios.post(
        "/api/graph/image_lscz?type_speech=" + typeSpeech,
        formData,
        {
          responseType: "blob",
        }
      )
      if (response.status == 200) {
        var reader = new FileReader()
        reader.onloadend = function () {
          cb(reader.result)
        }
        reader.readAsDataURL(response.data)
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
}

var client = new Psychomeasure()

export default client
