import axios from "axios"
import { apiUrl } from "@/env"
import { authHeaders } from "./utils"

class Session {
  constructor() {
    axios.defaults.baseURL = apiUrl
    console.debug("Host:", axios.defaults.baseURL)
  }
  async sessions(token, start, itemsPerPage, detailed) {
    const offset = start || 0
    const limit = itemsPerPage || 10
    const detail = detailed || false
    return axios.get(
      `/api/sessions/?offset=${offset}&per_page=${limit}&detailed=${detail}`,
      authHeaders(token)
    )
  }
  async loadSession(token, session_id) {
    return axios.get(`/api/sessions/${session_id}`, authHeaders(token))
  }

  async deleteSession(token, session_id) {
    return axios.delete(`/api/sessions/${session_id}`, authHeaders(token))
  }

  async transcribe(lang, data, transcriber, type) {
    var algo = transcriber || "aws"
    try {
      var response = await axios.post(
        "/api/session/audio?lang=" + lang + "&algo=" + algo + "&type=" + type,
        data
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return {}
  }
}

var client = new Session()

export default client
