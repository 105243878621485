import axios from "axios"
import { apiUrl } from "@/env"
import { authHeaders } from "./utils"

class User {
  constructor() {
    axios.defaults.baseURL = apiUrl
    console.debug("Host:", axios.defaults.baseURL)
  }

  async login(username, password) {
    const params = new URLSearchParams()
    params.append("username", username)
    params.append("password", password)
    return axios.post(`${apiUrl}/api/login/access-token`, params)
  }

  async passwordRecovery(username) {
    return axios.post(`${apiUrl}/api/password-recovery/${username}`)
  }

  async registerUser(user) {
    return axios.post(`${apiUrl}/api/users/open`, user)
  }

  async getMe(token) {
    return axios.get(`${apiUrl}/api/users/me`, authHeaders(token))
  }

  async users(token, start, itemsPerPage) {
    const offset = start || 0
    const limit = itemsPerPage || 10
    return axios.get(
      `/api/users/?skip=${offset}&limit=${limit}`,
      authHeaders(token)
    )
  }

  async addUser(token, user) {
    return axios.post(`/api/users/`, user, authHeaders(token))
  }
  async editUser(token, user) {
    return axios.put(`/api/users/${user.id}`, user, authHeaders(token))
  }
  async addRoles(token, user_id, roles) {
    return axios.put(`/api/users/${user_id}/role`, roles, authHeaders(token))
  }
  async deleteUser(token, user_id) {
    return axios.delete(`/api/users/${user_id}`, authHeaders(token))
  }

  async roles(token, start, itemsPerPage) {
    const offset = start || 0
    const limit = itemsPerPage || 10
    return axios.get(
      `/api/roles/?skip=${offset}&limit=${limit}`,
      authHeaders(token)
    )
  }
  async addRole(token, role) {
    return axios.post(`/api/roles`, role, authHeaders(token))
  }
  async addRolePermissions(token, role_id, roles) {
    return axios.put(
      `/api/roles/${role_id}/permission`,
      roles,
      authHeaders(token)
    )
  }
  async editRole(token, role) {
    return axios.put(`/api/roles/${role.id}`, role, authHeaders(token))
  }
  async deleteRole(token, role_id) {
    return axios.delete(`/api/roles/${role_id}`, authHeaders(token))
  }

  async permissions(token, start, itemsPerPage) {
    const offset = start || 0
    const limit = itemsPerPage || 10
    return axios.get(
      `/api/permissions/?skip=${offset}&limit=${limit}`,
      authHeaders(token)
    )
  }
  async addPermission(token, permission) {
    return axios.post(`/api/permissions`, permission, authHeaders(token))
  }
  async editPermission(token, permission) {
    return axios.put(
      `/api/permissions/${permission.id}`,
      permission,
      authHeaders(token)
    )
  }
  async deletePermission(token, permission_id) {
    return axios.delete(`/api/permissions/${permission_id}`, authHeaders(token))
  }
}

const client = new User()

export default client
