const state = () => ({
  activeMenu: "home",
})

export default {
  namespaced: true,
  state,
  /*
  actions,
  mutations,
  */
}
