require("./overrides.scss")

export default {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#93cac4",
        accent: "#0d6efd",
        secondary: "#8A8D93",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
        header: "#93cac4",
      },
      dark: {
        primary: "#93cac4",
        accent: "#0d6efd",
        secondary: "#8A8D93",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
      },
    },
  },
}
